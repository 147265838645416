<template>
  <div>
    <!-- <div>
      <TheTitleBar
        sectionTitle="dashboard.label.mnuDashboard"
        sectionID="sellerListings"
        :showCount="false"
        :showValue="false"
        :showFilters="false"
        :showSort="false"
        :showSellButton="true"
        :totalCount="0"
        :totalValue="0"
      />
    </div> -->

    <div class="dashboard-status-card-wrapper d-flex flex-column flex-md-row mx-auto">
      <template v-if="loading">
        <div
            class="item"
            v-for="(item, index) in new Array(3)"
            :key="index"
        >
          <v-skeleton-loader
              class="mx-auto my-2 px-2"
              max-width="100%"
              type="article"
          />
        </div>
      </template>

      <div
        v-for="(card, key) in $store.getters.sellerDashboardCard"
        :key="key"
        class="item"
        v-else
      >
        <TheDashboardStatusCard
          :card="card"
          :icon="key"
          :background="true"
          userType="seller"
        />
      </div>
    </div>

    <!-- <b-card-group deck class="m-auto"> -->
    <div class="col w-100 seller-box-wrapper px-0 mt-5">
      <div class="item seller-box-lg">
        <!-- <img src="@/assets/img/dashboard/car_listing.jpg" alt="" /> -->
        <div v-if="listNumber === 0">
          <h4>{{ $t("listings.label.noListing") }}</h4>
          <!-- <h6 class="text-half-dark pb-3">
              {{ $t("listings.label.noListingText") }}
            </h6> -->
        </div>
        <div>
          <TheSellButton class="px-5"/>
        </div>
      </div>

      <div class="item seller-box-lg">
        <!-- <img src="@/assets/img/dashboard/payment.jpg" alt="" /> -->
        <h4 class="">{{ $t("listings.label.yourPayments") }}</h4>
        <h6 class="pb-3 item-subtitle">
          {{ $t("listings.label.yourPaymentsText") }}
        </h6>
        <!-- <TheSellPaymentsButton v-show="false" /> -->
      </div>
    </div>
    <!-- </b-card-group> -->
  </div>
</template>

<script>
// import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";
import TheDashboardStatusCard from "@/components/dashboard/global/TheDashboardStatusCard.vue";
import TheSellButton from "@/components/dashboard/global/TheSellAVehicleButton.vue";
import TheSellPaymentsButton from "@/components/dashboard/global/TheSellPaymentsButton.vue";

export default {
  components: {
    TheSellButton,
    // TheTitleBar,
    TheSellPaymentsButton,
    TheDashboardStatusCard,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    listNumber() {
      const card = this.$store.getters.sellerDashboardCard;
      if (card) {
        return card[0].number1;
      } else return 0;
    },
  },
  methods: {
    async getInfo() {
      try {
        this.loading = true;
        let parameter = 4;
        await this.$store.dispatch("getSellerInfo", parameter);
      }finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.getInfo();
    localStorage.setItem("subMenu", "SellerDashboard")
  },
};
</script>

<style lang="scss">
.dashboard-status-card-wrapper {
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;
  padding-top: 24px;
  max-width: 400px;
  @media screen and (min-width:960px) {
    max-width:unset;
    .item {
      width: calc((100% / 3) - 16px);
    }
  }
}

.seller-box-lg {
  min-height: 335px;
  border-radius: 4px;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.seller-box-wrapper {
  display: flex;
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 44px;
  .item {
    width: calc((100% / 2) - 12px);
    background: white;
    border-bottom: 4px solid $light-blue !important;
    padding: 24px;
    @media (max-width: 991px) {
      width: 100%;
    }
    a {
      height: 48px;
      font-weight: 500 !important;
      font-size: 16px;
    }
    svg {
      margin-right: 8px;
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .item-subtitle {
      font-size: 14px;
      font-weight: 400;
      width: 400px;
      text-align: center;
      color: #363636;
      line-height: 20px;
      font-family: "Inter", sans-serif !important;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
}
</style>
