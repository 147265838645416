<template>
  <div
    v-bind:class="{ 'statusCardBG ': background }"
    class="dashboard-card"
    :style="`background-color: ${cardBackground}`"
  >
    <!-- BEGIN:: HIDE FILTERS FOR NOW -->
    <!-- <div
      v-show="card.Menu"
      class="p-0 my-auto"
      style="position: absolute; top: 0; right: 0"
    >
      <b-form-select
        size="sm"
        class="border-0 p-0 shadow-none w-auto text-right text-white d-inline-block mini-font"
        v-model="dropDown"
        @change="filter()"
      >
        <b-form-select-option
          :value="null"
          disabled
          class="bg-light mini-font"
          >Default</b-form-select-option
        >
        <b-form-select-option
          class="bg-light mini-font"
          v-for="(c, key) in card.Menu"
          :key="key"
          :value="c.value"
        >
          {{ c.description }}
        </b-form-select-option> </b-form-select
      ><b-icon icon="chevron-down" class="pl-1" scale="1"></b-icon>
    </div> -->
    <!-- END:: HIDE FILTERS FOR NOW -->

    <h4 class="dashboard-card-title" :class="userType === 'seller' ? 'seller' : ''">
      <b-link @click="redirect()" class="text-white">{{ card.title }}</b-link>
    </h4>

    <!-- Buyer Cards -->
    <div class="dashboard-card-subtitle" v-if="userType === 'buyer'">
      <b>{{ card.number1 }}</b>
      <span>{{ card.subtitle1 }}</span>
    </div>

    <!-- Seller Cards -->
    <div class="seller-info-wrapper" v-if="userType === 'seller'">
      <div class="text-center">
        <div>
          <h4>{{ card.number1 }}</h4>
        </div>
        <div>{{ card.subtitle1 }}</div>
      </div>

      <div class="text-center">
        <div>
          <h4 v-if="card.number2.includes('.')">
            {{ currencyFormatter(card.number2) }}
          </h4>
          <h4 v-else>{{ card.number2 }}</h4>
        </div>
        <div>{{ card.subtitle2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "background", "userType", "icon"],
  data() {
    return {
      dropDown: null,
      isStatus: false,
      watchlistIcon: require("@/assets/img/icons/heart_wht.svg"),
      reservedIcon: require("@/assets/img/icons/reserve_it.svg"),
      orderIcon: require("@/assets/img/icons/payment_delivery.svg"),
    };
  },
  methods: {
    currencyFormatter(value) {
      const sc = this.$store.getters.currencyForSeller;
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: sc ? sc : "CAD",
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    filter() {
      if (this.card.Menu) {
        this.isStatus = this.card.Menu.length > 4 ? true : false;
      }
      let parameter = {
        lang: this.$i18n.locale,
        status: this.isStatus ? this.dropDown : null,
        purchacedDate: !this.isStatus ? this.dropDown : null,
      };
      if (this.userType === "buyer") this.$store.dispatch("getBuyerInfo", parameter);
      else if (this.userType === "seller")
        this.$store.dispatch("getSellerInfo", parameter);
    },
    redirect() {
      if (this.userType === "seller") {
        switch (this.icon) {
          case 0:
            this.$router.push({ name: "MyListings" });
            break;
          case 1:
            this.$router.push({ name: "PendingSales" });
            break;
          case 2:
            this.$router.push({ name: "ConfirmedSales" });
            break;
        }
      } else if (this.userType === "buyer") {
        switch (this.icon) {
          case 0:
            this.$router.push({ name: "MyWatchList" });
            break;
          case 1:
            this.$router.push({ name: "MyReservations" });
            break;
          case 2:
            this.$router.push({ name: "MyPurchased" });
            break;
        }
      }
    },
  },
  computed: {
    cardBackground() {
      switch (this.icon) {
        case 0:
          return `#006AC3`;
        case 1:
          return `#F4B400`;
        case 2:
          return `#0F9D58`;
      }
    },
  },
};
</script>

<style lang="scss">

.statusCardBG {
  min-height: 180px;
  border-radius: 4px;
}
.dashboard-card {
  padding: 32px 24px;
  color: #fff;
  &-subtitle {
    width: 100%;
    height: 54px;
    margin-top: 30px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    b {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
.seller {
  &.dashboard-card-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
  }
}
.seller-info-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 4px;
  height: 118px;
  align-items: center;
  h4 {
    font-size: 20px;
    font-weight: 600;
    font-family: "Inter", sans-serif !important;
    margin-bottom: 16px;
  }
  div {
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
